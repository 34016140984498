import React, { useState, useEffect } from "react";
import "./dashboardStyle.css";

import AllSurveysList from "./components/AllSurveysList";

const DashbordHome = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container">
        <AllSurveysList />

        
      </div>
    </div>
  );
};

export default DashbordHome;
