import React, { useEffect, useState } from "react";
import LoginButton from "./components/login-button";
import { Drawer } from "antd";
import { NavLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header");
      if (window.scrollY > 50) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <header>
        <div className="header position-relative">
          <div className="nav-top">
            <div className="container d-flex align-items-center justify-content-end position-lg-relative">
              <div className="logo-wrapper">
                <NavLink className="big-logo" to="/dashboard">
                  <img src="./logo/websamp.png" alt="Big Logo" />
                </NavLink>
                <NavLink className="small-logo" to="/dashboard">
                  <img src="./logo/websamp3.png" alt="Small Logo" />
                </NavLink>
              </div>
              <div className="d-flex align-items-center">
                <div className={isOpen ? "nav-menu open" : "nav-menu "}>
                  <ul>
                 
                    <li>
                      <NavLink to="/about-us">About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to="/how-referral-works">Referral</NavLink>
                    </li>
                    <li>
                      <NavLink to="/how-it-works">How It Works</NavLink>
                    </li>
                  </ul>
                </div>
                <div className="bar me-4" onClick={handleDrawerToggle}>
                  <FaBarsStaggered />
                </div>
                <div className="wrapper d-flex align-items-center justify-content-end">
                  <LoginButton />
                </div>
              </div>
            </div>
          </div>
          <div className="nav-bottom">
            <div className="container p-2">
              <div className="marquee-container">
                <div className="marquee-text">
                  Powered By Continuum Insights
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
