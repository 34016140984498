import React, { useState, useEffect } from "react";
import { Button, Col, Drawer, Form, Input, Row, Select, Space } from "antd";
import { DatePicker } from "antd";
import { GetCountries } from "react-country-state-city";
const { Option } = Select;
const ExtraInfo = () => {
    const [open, setOpen] = useState(true);
    const [form] = Form.useForm();
    const [countryId, setCountryId] = useState(null);
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);

    
  useEffect(() => {
    
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);
    const handleCountryChange = (num) => {
        setCountryId(num);
        console.log(num)
        setStateList([]); 
        fetch("https://venkatmcajj.github.io/react-country-state-city/data/statesminified.json")
        .then(response => response.json())
        .then(data => {
          console.log("Filtered ", data);
          const filteredStates = data.filter((state) => state.id === num);
          console.log("Filtered states:", filteredStates);
         
          // setStateList(filteredStates);
        })
      };
      const handleSubmit = (formData) => {
        console.log(formData);
        setOpen(false);
      };
    
      const onClose = () => {
        setOpen(false);
      };
  return (
    <div>
        <Drawer
        className="imp-detail-form"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Submit
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={16}>
            {/* Email Field */}
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Please enter a valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* Phone Number Field */}
            <Col span={24}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: "Please enter phone number" },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* Gender Field */}
            <Col span={24}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[{ required: true, message: "Please select gender" }]}
              >
                <Select>
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Date of Birth Field */}
            <Col span={24}>
              <Form.Item
                name="dob"
                label="Date of Birth"
                rules={[
                  { required: true, message: "Please select date of birth" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            {/* Country Field */}
            <Col span={24}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  { required: true, message: "Please select country" },
                ]}
              >
                <Select onChange={handleCountryChange}>
                  {countriesList.map((item, index) => (
                    <Option key={index} value={item.isoCode}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* State Field */}
            <Col span={24}>
              <Form.Item
                name="state"
                label="State"
                rules={[{ required: true, message: "Please select state" }]}
              >
                <Select>
                  {stateList.map((item, index) => (
                    <Option key={index} value={item.isoCode}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Zip Code Field */}
            <Col span={24}>
              <Form.Item
                name="zipCode"
                label="Zip Code"
                rules={[
                  { required: true, message: "Please enter zip code" },
                  {
                    pattern: /^[0-9]{5}$/,
                    message: "Please enter a valid 5-digit zip code",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  )
}

export default ExtraInfo
