import React, { useEffect } from "react";
import BannerSlider from "./components/banner-slider";

import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressSurvey from "./components/progress-survey";
import TopBrands from "./components/top-brands";
import { useDispatch, useSelector } from "react-redux";
import { fetchSurveys } from "../redux/slice/homeSurveySlice";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth);
  const isLoggedIn = authStatus.isLoggedIn;
  const surveyStatus = useSelector((state) => state.homesurveys);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, []);
  useEffect(() => {
    dispatch(fetchSurveys());
  }, [dispatch]);

  return (
    <main>
      <section className="banner">
        <div className="container">
          <div className="banner-slider-wrapper py-5">
            <BannerSlider surveyStatus={surveyStatus} />
            <script src=""></script>
          </div>
        </div>
      </section>

      <section className="steps-wrapper  py-5">
        <div className="container">
          <div className="heading-section pb-4">
            <h2>Three Steps To Earn With Websamp</h2>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 mt-4">
              <div className="wrapper">
                <div className="img">
                  <img src="./images/login.png" alt="" />
                </div>
                <div className="text">
                  <h3>Register with us</h3>
                  <p>
                    Access your survey dashboard securely and effortlessly. Log
                    in to create, manage, and analyze your surveys with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-4">
              <div className="wrapper">
                <div className="img">
                  <img src="./images/survey.png" alt="" />
                </div>
                <div className="text">
                  <h3>Participate in Survey</h3>
                  <p>
                    Share your insights and help shape important decisions by
                    participating in our surveys. Your feedback is valuable and
                    contributes to meaningful outcomes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-4">
              <div className="wrapper">
                <div className="img">
                  <img src="./images/reward.png" alt="" />
                </div>
                <div className="text">
                  <h3>Earn Money</h3>
                  <p>
                    Get rewarded for your opinions! Participate in surveys and
                    earn money for sharing your valuable insights. Your feedback
                    not only matters but also pays.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <ProgressSurvey />
        </div>
      </section>
    </main>
  );
};

export default Home;
