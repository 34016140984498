import React, {useEffect} from "react";
 
import ReferTab from "./ReferTab";
 

const Referral = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <div>
   
        <div className="container-fluid">
              <ReferTab />
            </div>
    </div>
  );
};

export default Referral;
