import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../../redux/slice/sidebarSlice";
const DashboardBanner = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [shownavToggle, setshownavToggle] = useState(false);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };
  const closeToggleSidebar =() =>{
    dispatch(toggleSidebar(false));
    setshownavToggle(true)
  }
  if (!auth) {
    return <div>Loading...</div>;
  }

  console.log("dashboard auth", auth?.user);

  return (
    <div className="dash-content-wrapper">
      <div className=" px-sm-5 px-3 n-wrapper  d-flex align-items-center justify-content-between">
        <div className="dashboard-header-logo">
          <img src="./logo/websamp.png" alt="Big Logo" />
        </div>

        <div className="d-flex align-items-center justify-content-between">
          
          <div className={shownavToggle ? "sidenav-open  on":"sidenav-open"} onClick={handleToggleSidebar}>
            <FaBarsStaggered />
          </div>
          <div className="nav-menu px-3 py-3">
            <ul>
              <li>
                <NavLink to="/how-referral-works" onClick={closeToggleSidebar}>
                  Referral
                </NavLink>
              </li>
              <li>
                <NavLink to="/how-it-works" onClick={closeToggleSidebar}>
                  How It Works
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="user-info-wrapper position-relative">
            <div
              className="user-info-img"
              onClick={() => setShowUserInfo(!showUserInfo)}
            >
              <img
                src="https://img.icons8.com/bubbles/100/000000/user.png"
                alt=""
              />
            </div>
            {showUserInfo && (
              <div className="user-info-data-card">
                <h2 className="h4 mb-4">
                  Welcome, {auth?.user?.data?.user?.name}
                </h2>
                <p className="mb-1">
                  Your Balance - ₹{auth?.user?.data?.user?.balance?.INR}
                </p>
                <p>Referral Code - {auth?.user?.data?.user?.referral_code}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBanner;
