import React,{useEffect} from 'react'
import DashbordBanner from './components/DashboardBanner'
 import Transaction from "./Transactions"
import { useSelector } from 'react-redux'
const TransactionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isOpen = useSelector((state) => state.sidebar.isOpen);
  return (
    <div>
      
     <div className="container-fluid">
          <Transaction />
        </div>
  </div>
  )
}

export default TransactionPage
