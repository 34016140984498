import { Menu } from "antd";
import {
  LogoutOutlined,
  AppstoreOutlined,
  FormOutlined,
  UserOutlined,
  BarsOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../redux/slice/sidebarSlice";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    <NavLink to="/dashboard">
      <span className="icon">
        <AppstoreOutlined />{" "}
      </span>
      <span className="label">Dashboard</span>
    </NavLink>,
    "1"
  ),
  getItem(
    <NavLink to="/profile-survey">
      <span className="icon">
        <ImProfile />
      </span>{" "}
      <span className="label">Profile Surveys</span>
    </NavLink>,
    "2"
  ),
  // getItem(
  //   <NavLink to="/survey">
  //     <span className="icon">
  //       <FormOutlined />
  //     </span>{" "}
  //     <span className="label"> Surveys</span>
  //   </NavLink>,
  //   "3"
  // ),
  getItem(
    <NavLink to="/personal-details">
      <span className="icon">
        <UserOutlined />
      </span>{" "}
      <span className="label">Personal Details</span>
    </NavLink>,
    "4"
  ),
  getItem(
    <NavLink to="/transaction">
      <span className="icon">
        <BarsOutlined />
      </span>{" "}
      <span className="label">Transaction</span>
    </NavLink>,
    "5"
  ),
  getItem(
    <NavLink to="/refer">
      <span className="icon">
        <UsergroupAddOutlined />
      </span>{" "}
      <span className="label">Refer Details</span>
    </NavLink>,
    "6"
  ),
  getItem(
    <NavLink to="/sign-out">
      <span className="icon">
        <LogoutOutlined color="red" />{" "}
      </span>
      <span className="label">Sign Out</span>
    </NavLink>,
    "7"
  ),
];

function Sidenav() {
  const isOpen = useSelector((state) => state.sidebar.isOpen);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      <div
        className={
          isOpen ? "dashbord-nav-wrapper" : "dashbord-nav-wrapper close-nav"
        }
      >
        <div className="close-arrow" onClick={handleToggleSidebar}>
          <MdKeyboardDoubleArrowLeft />
        </div>
        <div className="brand">
          <img src="./logo/websamp6.png" alt=" websamp logo" />
        </div>
        <div className="sidebar">
          <Menu
            mode="inline"
            theme="light"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            items={items}
          ></Menu>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
