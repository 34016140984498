import React, { useState } from "react";
import { Progress, Radio, Space } from "antd";

const ProgressSurvey = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [votes, setVotes] = useState({
    html: 0,
    css: 0,
    js: 0,
    ram:0,
    shyam:0,
    raju:0,
  });
  const [totalVotes, setTotalVotes] = useState(0);

  const handleVote = (e) => {
    const language = e.target.value;
    setSelectedOption(language);

    setVotes((prevVotes) => ({
      ...prevVotes,
      [language]: prevVotes[language] + 1,
    }));
    setTotalVotes(totalVotes + 1);
  };

  const calculatePercentage = (count) => {
    return totalVotes === 0 ? 0 : ((count / totalVotes) * 100).toFixed(2);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <Space
            className="poll-survey"
            direction="vertical"
            size="large"
            style={{ width: "100%" }}
          >
            <h4>What do you like most?</h4>
            <Radio.Group
              className="w-100"
              onChange={handleVote}
              value={selectedOption}
            >
              <div className="d-flex align-items-center pro-cover mb-4">
                <Radio value="html"></Radio>{" "}
                <div className="progress-wrapper flex-grow-1 ms-3">
                  <p>HTML</p>
                  <Progress percent={calculatePercentage(votes.html)} />
                </div>
              </div>
              <div className="d-flex align-items-center pro-cover mb-4">
                <Radio value="css"></Radio>
                <div className="progress-wrapper flex-grow-1 ms-3">
                  <p>CSS</p>
                  <Progress percent={calculatePercentage(votes.css)} />
                </div>
              </div>
              <div className="d-flex align-items-center pro-cover mb-4">
                <Radio value="js"></Radio>
                <div className="progress-wrapper flex-grow-1 ms-3">
                  <p>JavaScript</p>
                  <Progress percent={calculatePercentage(votes.js)} />
                </div>
              </div>
            </Radio.Group>
          </Space>
        </div>
        <div className="col-md-6">
          <Space
            className="poll-survey"
            direction="vertical"
            size="large"
            style={{ width: "100%" }}
          >
            <h4>What do you like most?</h4>
            <Radio.Group
              className="w-100"
              onChange={handleVote}
              value={selectedOption}
            >
              <div className="d-flex align-items-center pro-cover mb-4">
                <Radio value="ram"></Radio>{" "}
                <div className="progress-wrapper flex-grow-1 ms-3">
                  <p>Ram</p>
                  <Progress percent={calculatePercentage(votes.ram)} />
                </div>
              </div>
              <div className="d-flex align-items-center pro-cover mb-4">
                <Radio value="shyam"></Radio>
                <div className="progress-wrapper flex-grow-1 ms-3">
                  <p>Shyam</p>
                  <Progress percent={calculatePercentage(votes.shyam)} />
                </div>
              </div>
              <div className="d-flex align-items-center pro-cover mb-4">
                <Radio value="raju"></Radio>
                <div className="progress-wrapper flex-grow-1 ms-3">
                  <p>Raju</p>
                  <Progress percent={calculatePercentage(votes.raju)} />
                </div>
              </div>
            </Radio.Group>
          </Space>
        </div>
      </div>
    </>
  );
};

export default ProgressSurvey;
