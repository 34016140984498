import React, {useEffect} from "react";
import FAQ from "./components/FAQ";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <main>
        <div className="container py-5">
          <div className="row d-flex align-items-center">
             <div className="col-md-6 pe-md-5">
              <div className="wrapper">
                <div>
                  <div className="section-title text-start mb-4 ">
                    <h2 className="whiteColor">WELCOME to Websamp</h2>
                  </div>
               
                  <p className="value-two__text text-start">
                    Started in 2018, with a dream of providing the best value
                    for our consumers and clients, WebSamp is driven to a change
                    in how brands create and launch products. We champion market
                    research and technology. When you sign up on our tool, you
                    can be rest assured of your data security. All the survey
                    responses are completely anonymous and no personal
                    information is shared with our clients. You can refer to our
                    FAQ section, for more detailed information or if you wish to
                    have a direct response you can fill our contact form on the
                    contact page. We’d be happy to solve any of your queries.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper">
              <div class="img fix-height-img">
                  <img className="w-100 h-auto" src="./images/about-us.jpg" alt="" />
                </div>
              </div>
            </div>
           
          </div>
        </div>

        <section>
          <div className="container py-5">
            <FAQ />
          </div>
        </section>
      </main>
    </div>
  );
};

export default About;
