import React from 'react'

const Footer = () => {
  return (
    <footer className="border-top">
    <div className="container footer-contaner py-5 pt-5">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-6 ">
          <div>
            <div className="footer-logo">
                <img src="./logo/websamp2.png" alt="" />
            </div>
            <p>
            Websamp connects brands with consumers through surveys. Brands get feedback, consumers earn rewards.
            </p>
          </div>
         
        </div>
     
        <div className="col-lg-3 col-md-4 col-sm-6 offset-lg-2">
          <h3>Main menu</h3>
          <ul className="ps-0 hoverable">
            <li>
              <a href="/term-service">Term Service </a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy </a>
            </li>
            <li>
              <a href="survey-programming/">About Us </a>
            </li>
          
          </ul>
        </div>
    
        <div className="col-lg-3 col-md-4 col-sm-6">
          <h3>Our App</h3>
          <div className="app-link-wrapper">
            <a href="#">
              <img src="./images/ios.webp" alt="" />
            </a> <br />
            <a className="pt-3 d-inline-block" href="https://play.google.com/store/apps/datasafety?id=com.websamp.app">
              <img src="./images/android.webp" alt="app" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright py-2">
      <div className="container">
        <div className="row">
          <div className="col">
            <p className="mb-0 text-center">
              Copyright © 2024 Websamp
            </p>
          </div>
        </div>
      </div>
    </div>
    </footer>
  )
}

export default Footer
