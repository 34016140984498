import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  DatePicker,
  Space,
  Select
} from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateMe } from "../../redux/slice/authSlice";
import moment from 'moment';
import "./details.css"



const EditUserDrawer = ({
  setIsEditableDrawerOpen,
  reloadUser
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector(({ auth }) => auth);
  const onSaveSuccess = () => {
    setIsEditableDrawerOpen(false);
    reloadUser();
  }

  const onFinish = (value) => {
    console.log("details:", value);
    dispatch(updateMe(value));

  };
  useEffect(() => {
    console.log("userInformation:", user)
    if (Object.keys(user).length) {
      var dob = user.data.user.dob != null ? moment(user.data.user.dob, "YY MMM DD,") : null
      form.setFieldsValue({
        username: user.data.user.username,
        email: user.data.user.email,
        name: user.data.user.name,
        mobile: user.data.user.mobile,
        gender: user.data.user.gender,
        dob: dob,
        country: user.data.user.country
      });
    }
  }, [user, form]);
  const onClose = () => {
    setIsEditableDrawerOpen(false);
  };

  
  const screenWidth = window.innerWidth;
  const drawerWidth = screenWidth > 576 ? 700 : screenWidth - 32; 
  return (
    <>
     
      <Drawer
        title="Edit Your Details"
       className="editUserDrawer"
        closable={false}
        onClose={onClose}
        open={true}
        placement="right"
         style={{ position:"fixed", right: 0 }}
        
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button form="survey_submit_form" key="submit" htmlType="submit" type="primary">
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
         <div className="drawer-content-container">
        <Form
          id="survey_submit_form"
          layout="vertical"
          hideRequiredMark
          form={form}
          onFinish={onFinish}
          
        >
          <Divider>Personal Information</Divider>
          <Row gutter={16}>
            <Col span={20}>
              <Form.Item name="username" label="Username" style={{marginLeft: 2}}>
                <Input disabled={true} defaultValue={user.username} />
              </Form.Item>
            </Col>
            <Col span={20} style={{marginLeft: 2}}>
              <Form.Item name="email" label="Email" style={{marginLeft: 2}}>
                <Input disabled={true}/>
              </Form.Item>
            </Col>
            
          </Row>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item name="name" label="Name" style={{marginRight:'20px'}}>
                <Input  />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name="mobile" label="Phone">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item name="gender" label="Gender" style={{marginRight:'20px'}}>
                <Select
                  // listItemHeight={5}
                  listHeight={200}
                  // size="large"
                  placeholder="Select Gender"
                  optionFilterProp="children"
                  options={["MALE", "FEMALE", "OTHER"].map(option => { return { value: option, label: option } })}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
            <Form.Item
              name="dob"
              label="Date of Birth"
              rules={[
                {
                  required: true,
                },
              ]}
            >
            <DatePicker placeholder="Select Date of birth" style={{width:'100%'}}></DatePicker>
          </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            
            <Col span={10}>
              <Form.Item name="country" label="Country">
                <Input disabled={true}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        </div>
      </Drawer> 
      
    </>
  );
};
export default EditUserDrawer;