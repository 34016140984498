import React,{useEffect} from 'react'
 
import AllSurveysList from "./components/AllSurveysList"
 

const SurveyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <div>
     
     <div className="container">
 <AllSurveysList />   
  </div>
  </div>
  )
}

export default SurveyPage
