import React, {useEffect} from 'react'

const HowItWorks = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
       <section className="steps-wrapper  py-5">
        <div className="container">
          <div className="heading-section  pb-4">
            <h2 className='text-center'>Three Steps To Earn With Websamp</h2>
            <p></p>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 mt-4">
              <div className="wrapper">
                <div className="img">
                  <img src="./images/login.png" alt="" />
                </div>
                <div className="text">
                  <h3>Register with us</h3>
                  <p>
                    Access your survey dashboard securely and effortlessly. Log
                    in to create, manage, and analyze your surveys with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-4">
              <div className="wrapper">
                <div className="img">
                  <img src="./images/survey.png" alt="" />
                </div>
                <div className="text">
                  <h3>Participate in Survey</h3>
                  <p>
                    Share your insights and help shape important decisions by
                    participating in our surveys. Your feedback is valuable and
                    contributes to meaningful outcomes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mt-4">
              <div className="wrapper">
                <div className="img">
                  <img src="./images/reward.png" alt="" />
                </div>
                <div className="text">
                  <h3>Earn Money</h3>
                  <p>
                    Get rewarded for your opinions! Participate in surveys and
                    earn money for sharing your valuable insights. Your feedback
                    not only matters but also pays.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<section className='bg-dark py-5'>
<div class="container text-center">
        <h2 class="text-white my-5">Earn more by inviting friends</h2>
        <div class="row">
            <div class="col-md-4 step-container">
            <div class="step-icon">
                    <span class="step-icon-number">1</span>
                </div>
                <h4 class="text-white">Register and Earn Rewards</h4>
                <p class="step-description"> Create your profile with us by signing up using your Google account. After registering, you’ll gain access to a variety of surveys and studies. Complete these surveys to earn rewards.
                </p>
            </div>
            <div class="col-md-4 step-container">
                <div class="step-icon">2</div>
                <h4 class="text-white">Refer and Boost Your Earnings</h4>
                <p class="step-description">  Refer friends, family, or colleagues to join our platform. For every survey they complete, you’ll earn 10% of their earnings.</p>
            </div>
            <div class="col-md-4 step-container">
                <div class="step-icon">3</div>
                <h4 class="text-white">Enjoy Unlimited Earning Potential</h4>
                <p class="step-description">  There’s no limit to how much you can earn. The more people you refer, the more you can increase your earnings.</p>
            </div>
        </div>
    </div>
</section>

    </div>
  )
}

export default HowItWorks
