import TransactionHistory from "./transactionhistory";
import Withdraw from "./withdraw";

function Transaction(){
  return(
    <>
    <div className="row">
      <div className="col-lg-8">
         <TransactionHistory></TransactionHistory>
      </div>
      <div className="col-lg-4 mt-4 mt-lg-0">
        <Withdraw className="h-100"></Withdraw>
      </div>
    </div>
    
    
    </>
  )
}
export default Transaction;
