import React, { useEffect } from "react";

import { FaUser } from "react-icons/fa";
import GoogleLoginButton from "./GoogleLoginBtn";

const LoginButton = () => {
  return (
    <div className="btn-wrapper position-relative">
      <button>
        <span className="l-icon">
          <FaUser />
        </span>
        <span className="ms-2">
          <span>You</span>
          <br />
          <span className="text-warning">$0</span>
        </span>
      </button>
      <ul className="dropdown-menu">
        <li>
          <GoogleLoginButton />
        </li>
      </ul>
    </div>
  );
};

export default LoginButton;
