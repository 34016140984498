import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSurveyList } from "../../redux/slice/surveySlice";
import { NavLink } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserSurveyList({ order_by: ["-created_at"] }));
  }, [dispatch]);

  const { surveys = {} } = useSelector(
    ({ assignedSurveys }) => assignedSurveys
  );
  const { items = [] } = surveys;

  const ipDetail = JSON.parse(localStorage.getItem("ip_details") || "{}");

  const openInNewTab = (url) => {
    window.open(
      `${url}&ip=${ipDetail.ip}&country=${ipDetail.country}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      {items.length > 0 ? (
        items.map((item, index) => (
          <div className="site-card-wrapper">
            <h2 className="h3 mb-4">Surveys Assigned</h2>
            <div className="cards-container row">
              <div className="col-md-3" key={index}>
                <div
                  className="survey-card"
                  onClick={() => openInNewTab(item.survey.url)}
                >
                  <div className="img">
                    <img src={item.survey.ui_icon} alt={item.survey.ui_title} />
                  </div>
                  <p>
                    Earn - {item.survey.respondent_cpi}{" "}
                    {item.survey.respondent_currency}
                  </p>
                  <span className="start-btn">Start Survey</span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="no-surveys">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="wrapper text-start">
                <h1 className="mb-4">No Surveys Available</h1>
                <h3>We're Sorry!</h3>
                <p>
                  It looks like there are no surveys available at the moment.
                  We're constantly adding new opportunities for you to share
                  your opinions and earn rewards. Please check back later, or
                  explore other areas of our site in the meantime.
                  
                </p>
                <NavLink className="btn btn-dark" to="/profile-survey"> Earn More</NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <div className="wrapper">
                <img src="./images/survey-not-found.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
